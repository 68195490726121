<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!--
  Notes:

  The way we display a story or an error is very strange
   compared to normal vue design.
  This is unfortunately due to the storyblok plugin integration with Nuxt.

  Often, the backend generate the correct html,
   but the frontend encounters an error.

  The problem is when this happens,
   the frontend will replace the story variable,
   erasing everything on the user interface

  This is not what we want,
   instead we want to display an error when this happens,
    and still display the original story
  -->
  <div v-if="errorMessage">
    {{ errorMessage }}
  </div>
  <!-- when there is a hero, display it -->
  <div
    v-if="
      story!.content.PageType === 'FSHero' || story!.content.PageType === 'hero'
    ">
    <TopHero
      :heroContent="story!.content.heroContent"
      :fullSizeHero="story!.content.PageType === 'FSHero'" />
  </div>
  <!-- if there is a blog instead, display it -->
  <div v-if="story!.content.PageType === 'blog'">
    <NavPadding />
    <TopBlog
      :blogImage="story!.content.blogImage"
      :blogAuthor="story!.content.blogAuthor"
      :title="
        story!.content.altTitle && story!.content.altTitle?.length > 0
          ? story!.content.altTitle
          : story!.name
      "
      :publishedDate="story!.sort_by_date" />
  </div>
  <!-- if this is a regular page, put empty space behind the navbar -->
  <div v-if="story!.content.PageType === 'regular'">
    <NavPadding />
  </div>

  <!-- finally, display the content of the story -->
  <DisplayContainer>
    <StoryblokComponent
      v-for="childBlok in story!.content.pageContent"
      :key="childBlok._uid"
      :blok="childBlok" />
  </DisplayContainer>
</template>

<script setup lang="ts">
const storyblokApi = useStoryblokApi();
const route = useRoute();
const { slug } = route.params;

// get the path from the url and fetch the story from storyblok
let path: string = '';
let story: storyInterface | null = null;
const errorMessage: Ref<string | undefined> = ref();
switch (slug.length) {
  case 0: {
    path = 'home';
    break;
  }
  case 1: {
    path = slug[0];
    break;
  }
  default: {
    // remove empty slug (it can happen when loading the page directly)
    let filtered = (slug as string[]).filter((el) => el !== '');
    path = filtered.join('/');
  }
}
try {
  const { data } = await storyblokApi.get(`cdn/stories/${path}`, {
    version: useRoute().query._storyblok ? 'draft' : 'published',
  });
  story = data.story;
} catch (error) {
  // @ts-expect-error no need for a complex type checking
  if (error.status === 404) {
    errorMessage.value = 'the page was not found';
  }
  // @ts-expect-error no need for a complex type checking
  if (error.status === 401) {
    errorMessage.value =
      'It seems you are using ad blockers, our website has difficulties working with those';
  }
}

/// ////////////////////
//       SEO         //
/// ////////////////////
// Set the meta using a story, or fallback to default in case of issues
let title = 'Ansearch - All your organisation knowledge in one place';
let href = 'https://ansearch.net';
if (story && story.name !== 'Home') {
  // leave the home page with the default title
  title = `Ansearch | ${story.name}`;
  // The additional slash is due to Cloudflare's way of serving files (without the slash, a redirect is made, resulting in the page not being indexed by search engines)
  href = `https://ansearch.net${route.path}`;
}
const description = story
  ? story.content.SEODescription
  : 'Ansearch let you find any information from your company easily';

// finally, set all of this in the page head
useServerHead({
  link: [{ rel: 'canonical', href }],
  htmlAttrs: {
    lang: 'en',
  },
});
useServerSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
  ogUrl: href,
  ogImage: story?.content.blogImage?.filename ?? '',
});
useSeoMeta({
  title,
});
</script>
